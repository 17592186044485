/* content & co */

.sub-header-text { //section titles, subheading 
    @include type_h2;
    padding-top: 6rem;
    padding-bottom: 3rem;
    color: #094063;
    text-align: center;
}

.common-body-text { //typography
    @include type_p;
    font-weight: 300;
    color: $text-03;
}

.common-title-text { //typograhy 
    @include type_h4;
    color: $text-01;
}

.content-container {
    display: flex;
    width: 100%;
    min-height: 50rem;
}

.content-container-wrap { 
    align-items: flex-start;
    padding: 1rem 0;
}

.content-container__text-box {

    h2 {
        text-align: left;
        margin-bottom: 1rem;
    }
}

.content-container__text-item {
    padding-top: 1.5rem;
}

.content-container__list-item {
    padding-top: 1rem;
    width: 100%;

    li {
        font-size: 1.6rem;
        color: $text-03;
        line-height: 2rem;
        font-weight: 300;
        padding: 5px 0;
        list-style: disc;
        list-style-position: inside;
    }
}

.content-container__illustration {
    width: 40rem;
    padding-top: 10rem;
}


.content-container_with-title { 
    @extend .content-container;
    background-color: $ui-10;
    flex-direction: column;
}

/* col = column  rows & column */
.common-col-wrap {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.common-row-wrap {
    @extend .common-col-wrap;
    flex-direction: column;
}

.common-col {
    width: 46rem;
}

.common-row {
    justify-content: center;
    margin: 3rem 0;
    align-items: baseline;
}

.common-col-section {
    height: 24rem;
    text-align: center;
}

.common-col-icon {
    margin-bottom: 2rem;
    height: 4rem;
}

.common-col-text_center {
    text-align: center;
    margin: 2rem;
    width: 42rem;
}

.common-row-section {
    text-align: center;

    .common-title-text {
        font-size: 1.8rem;
    }

}

.common-row-icon {
    @extend .common-col-icon;
}

.common-row-text {
    padding-top: 1rem;
    text-align: justify;
}


.common-section-text {
    text-align: center;
    max-width: 90rem;
    align-self: center;
}

.common-col-illustration{
    height: 16rem;
    margin-bottom: 2rem;
}


/* tabs   */ 

@media screen and (max-width: 1024px) {

    .content-container__illustration {
        width: 32rem;
        padding-top: 0;
    }


}


/* mobile */

@media screen and (max-width: 768px ){
    
    .content-container {
        
        min-height: 60rem;
    }

    .content-container-wrap_reverse {
        flex-wrap: wrap-reverse; 
    }

    .content-container__illustration {
        width: 22rem;
        padding-top: 0;
    }

    .common-col-wrap {
        flex-direction: column;
        align-items: center;
    }

    .common-row {
        text-align: center;
        align-items: center;
    }

    .common-row-section {
        margin: 1rem 0;
    }

    .common-col {
        width: auto;
    }

    .common-col-icon {
        height: 4rem;
        margin-bottom: 1rem;
        margin: 2rem 0;
    }

    .common-title-text {
        font-size: 2rem;
    }

    .common-body-text {
        font-size: 1.4rem;
        line-height: 2rem;
    }

    .common-col-text_center {
        width: auto;
        margin: 0;
    }


}


