$text-01: #212121;
$text-02: #4f4f4f;
$text-03: #333333;

//appear in backgrounds etc
$ui-01: #ffffff;
$ui-02: #fdfefe;
$ui-03: #f2f2f2;
$ui-04: #e6f0f9;
$ui-05: #e5e5e5;
$ui-06: #c4c4c4;
$ui-07: #fbfbfb;
$ui-08: #f9fafc;
$ui-09: #819fb1;
$ui-10: #fbfcfd;
$ui-11: #eee;

$brand-01: #094063;
$brand-02: #107bc0;
$brand-03: #5199d3;
$brand-04: #89cbf0;
$brand-05: #c0e5e5;

$brand-hover: #003154;
$brand-focus: #093b5a;
$brand-disabled: #aac2d8;

$danger: #ab0404;
$danger-hover: #920000;
$danger-focus: #9c0404;
$danger-disabled: #d88c8c;

//semantic colours
$success: #44d087;
$warning: #ffd144;
$info: #44a3e6;
$error: #d64444;

$color-black: #212121;
$color-white: #ffffff;
$color-blue-one: #094063;
$color-blue-two: #107bc0;
$color-blue-three: #5199d3;
$color-blue-four: #89cbf0;
$color-blue-hover: #07314b;
$color-blue-five: #c0e5e5;
$color-grey-one: #333333;
$color-grey-two: #4f4f4f;
$color-grey-three: #cccccc;
$color-green: #11bd86;
$color-red: #ab0404;