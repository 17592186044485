/* dropdown */

.navbar__dropdown {
    position: relative;
    height: 8rem;
    display: flex;
    align-items: center;

    ul {
        display: none;
    }
}

.navbar__dropdown:hover{
    ul {
        display: block;
        position: absolute;
        top: 75px;
        left: 0;
        background: $ui-01;
        border: 1.5px solid $info;
        width: 30rem;
        border-radius: 4px;
        padding: 1rem;
    }
}

.navbar__dropdown-item {
    li {
        padding: 1rem;

        a {
            color: #212121 !important;
        }
    }
}

.navbar__dropdown-item li:hover {
    a {
        color: $brand-02 !important;
    }
}


/* tab  */ 

@media screen and (max-width: 1024px) {



}


/* mobile */

@media screen and (max-width: 768px ){

    .navbar__dropdown {
        flex-direction: column;
        align-items: end;
        height: auto;
    }

    .navbar__dropdown:hover{
        ul {
            display: block;
            position: inherit;
            top: auto;
            margin: 2rem 0;
            height: auto;
            width: -webkit-fill-available;
        }
    }  

}


