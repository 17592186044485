.invoice__form-section{
    border: 1px solid $ui-06;
    padding: 4.4rem;
}
.invoice__form-section:not(:last-of-type){
margin-bottom: 5.2rem;
}

.invoice__form-header {
    font-size:2.4rem;
    margin: 9.7rem auto 2.4rem auto;
}
.field__info {
    font-size: 1.4rem;
    font-weight: 600;
    color: $color-grey-two;
}