.justify-start {
  display: flex;
  justify-content: flex-start;
}
.justify-center {
  display: flex;
  justify-content: center;
}
.flex-col {
  flex-direction: column;
}
.align-items-end {
  align-items: flex-end;
}
.align-items-center {
  align-items: center;
}
.self-end {
  align-self: flex-end;
}

.margin-top {
  margin-top: 1em;
}
.margin-bottom {
  margin-bottom: 1em;
}
.margin-right {
  margin-right: 1em;
}
.ml-auto {
  margin-left: auto;
}
.mt-4 {
  margin-top: 4rem;
}
.mt-6 {
  margin-top: 6rem;
}
.mt-8 {
  margin-top: 8rem;
}
.mx-auto {
  display: block;
  margin-left: auto;
  margin-right: auto;
}